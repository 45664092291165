import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { string } from 'prop-types';
import { Link } from 'gatsby';

export default function SectionHeader({ title }) {
  return (
    <Grid container justify="center" alignItems="center" className="breadcrumbContainer">
      <Typography id="BreadCrumb" className="breadCrumbListTitle">
        <Link className="breadCrumbLink" to="/">Home</Link>
        {' '}
        <span className="breadCrumbArrow">&gt;</span>
        {title}
      </Typography>
    </Grid>
  );
}

SectionHeader.propTypes = {
  title: string.isRequired,
};
