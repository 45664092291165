export default function returnPaginatedData(rawData, postsPerPage, currentPage) {
  const returnData = [];
  let includedPosts = 0;
  const pageOffset = postsPerPage * (currentPage - 1);
  rawData.some((row, index) => {
    const postNumber = index + 1;
    if (postNumber > pageOffset) {
      returnData.push(row);
      includedPosts += 1;
    }
    return includedPosts === postsPerPage;
  });
  return returnData;
}
