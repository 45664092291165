import React from 'react';
import { graphql } from 'gatsby';
import { instanceOf } from 'prop-types';
import { Grid, Hidden } from '@material-ui/core';
import Layout from '../../components/layout';
import ArticleGrid from '../../components/template-parts/ArticleGrid';
import returnPaginatedData from '../../utils/dataPagination';
import '../../assets/app.css';
import CategoryBreadcrumbs from '../../components/CategoryBreadcrumbs'

const CategoryPage = ({ data: { allWpCategory }, pageContext }) => {
  const nodes = returnPaginatedData(
    allWpCategory.nodes[0].posts.nodes,
    pageContext.categoryPostsPerPage, pageContext.page,
  );
  return (
    <Layout>
      <div id="back-to-top-anchor" />
      <Hidden smUp><CategoryBreadcrumbs title={pageContext.pageName} /></Hidden>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid className="postContainer" item xs={12}>
          <ArticleGrid
            listTitle={pageContext.pageName}
            posts={nodes}
            route="category"
            pageContext={pageContext}
            paginate
            hidePageTitle
            breadcrumbs
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

CategoryPage.propTypes = {
  data: instanceOf(Object).isRequired,
  pageContext: instanceOf(Object).isRequired,
};

export default CategoryPage;

export const query = graphql`
    fragment Thumbnail on File {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }

    query CategoryPage($pageName: String) {
        allWpCategory(
            filter: {name: {in: [$pageName]}}
        ) {
            nodes{
                name
                posts {
                    nodes {
                        uri
                        title
                        categories {
                          nodes {
                            name
                          }
                          }
                        featuredImage {
                            node {
                                localFile {
                                    ...Thumbnail
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
